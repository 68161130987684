  .open {
      position: absolute;
      height: 100%;
      width: 100%;
      background-color: black;
      opacity: 0.8;
      visibility: visible;
      z-index: 3;
      -webkit-animation: fade-in 1s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
      animation: fade-in 1s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
      display: flex;
      align-items: center;
      justify-content: center;
  }

  .close {
      position: absolute;
      visibility: hidden;
      -webkit-animation: fade-out 5s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
      animation: fade-out 5s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  }

  @-webkit-keyframes fade-in {
      0% {
          opacity: 0;
      }

      100% {
          opacity: 0.8;
      }
  }

  @keyframes fade-in {
      0% {
          opacity: 0;
      }

      100% {
          opacity: 0.8;
      }
  }

  @-webkit-keyframes fade-out {
      0% {
          opacity: 0.8;
      }

      100% {
          opacity: 0;
      }
  }

  @keyframes fade-out {
      0% {
          opacity: 0.8;
      }

      100% {
          opacity: 0;
      }
  }

  .content-modale {
      width: 60%;
      display: flex;
      flex-direction: column;
      align-items: center;
      color: grey;
      height: 80%;
      text-align: center;
  }

  @media screen and (min-width : 688px) {

      .content-modale h3 {
          font-family: GuyonGazebo;
          font-size: 4rem;
          color: orange;
          margin: 0;
      }

      .content-modale h6 {
          font-family: BlackSemiCondensed;
          font-size: 2rem;
          color: white;
          margin: 0;
          width: 90vw;
      }

      .link-discord {
          padding-top: 3%;
          padding-bottom: 15%;
      }

  }

  @media screen and (max-width: 687px) {

      .content-modale h3 {
          font-family: GuyonGazebo;
          font-size: 3rem;
          color: orange;
          margin: 0;
          width: 90vw;
      }

      .content-modale h6 {
          font-family: BlackSemiCondensed;
          font-size: 1rem;
          color: white;
          margin: 0;
          width: 90vw;
      }

      .link-discord {
          padding-top: 5%;
          padding-bottom: 30%;
      }

  }