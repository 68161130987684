.menu_button {
  background-color: transparent;
  border: 0.5rem solid;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 50%;
  font-family: GuyonGazebo;
  transform: translateY(-50%);
}

.menu_button:nth-child(1){
    animation: 1s fade-simple ease-out forwards;
}

.menu_button:nth-child(2){
    animation: 1.5s fade-simple ease-out forwards;
}

.menu_button:nth-child(3){
    animation: 2s fade-simple ease-out forwards;
}

@keyframes fade-simple {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }

  100% {
    opacity: 1;
  }
}

@media screen and (min-width : 688px) {

  .menu_button {
    font-size: 6rem;
  }

}

@media screen and (max-width: 687px) {

  .menu_button {
    font-size: 13vw;
  }

}