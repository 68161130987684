body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "Sunwish";
  src: local("Sunwish"),
    url("./assets/fonts/Sunwish-Maverick.otf") format("opentype");
}


@font-face {
  font-family: "Deserved";
  src: local("Deserved"),
    url("./assets/fonts/Deserved.ttf") format("truetype");
}

@font-face {
  font-family: "GuyonGazebo";
  src: local("GuyonGazebo"),
    url("./assets/fonts/GuyonGazebo-Regular.otf") format("opentype");
}

@font-face {
  font-family: "BlackSemiCondensed";
  src: local("BlackSemiCondensed"),
      url("./assets/fonts/BlackSemiCondensed.otf") format("opentype");
}
