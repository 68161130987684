body {
    background-image: url('./assets/img/grainy-gradients.jpg');
    background-size: 100% 100%;
    overflow: hidden;
}

.box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    row-gap: 3%;
}

.ban-box {
    display: flex;
    justify-content: space-between;
}

.stars {
    color: white;
    z-index: 3;
    width: 4rem;
    height: 4rem;
    transition: 1s;
}

.stars:hover {
    transform: rotate(180deg);
    transition: 1s;
}

.title {
    font-family: Sunwish;
    font-size: 2.2em;
    padding-left: 1%;
    color: white;
}